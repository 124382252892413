<template>
  <div class="smb-client-wrapper">
    <Loader v-if="isLoading" />

    <template v-else>
      <SmbTitles
        is-show-title
        is-show-sub-title
        title="Vocabulary"
        sub-title="Select the topic of the tip you want us to generate for you."
      />
      <div class="buttons-wrapper">
        <SmbGeneralButton
          v-for="button in vocabularyButtons"
          :key="button.id"
          :text="button.name"
          :isLoading="button.isLoading"
          :emitName="button.emitName"
          @click="checkIsNeedBrandingUpdatesBeforeSurpriseMe(button)"
        />
      </div>
      <div class="smb-client-wrapper__footer">
        <SmbActionBtn
          text="Back"
          @click="$router.push({ name: 'generate-content' })"
        />
      </div>
      <SmbAddBrandingDetailsPopUp
        :isShow="isShowBrandingPopUp"
        :actionType="isNeedToUpdateBranding"
        :buttonsData="{
          button: lastClickedBtnData,
          type: getCurrentCreationContentType,
        }"
        :emit-name="emitNameForActionAfterPopup"
        @close="isShowBrandingPopUp = false"
        @clickOnBtnHandler="clickOnBtnHandler(lastClickedBtnData)"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  VOCABULARY_BUTTONS,
  SUBJECT_IDS,
  POP_UP_TEXT,
} from "@/constants/smb-client";

import Loader from "@/components/global/Loader";
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbGeneralButton from "@/components/smb-client/SmbGeneralButton";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbPopup from "@/components/smb-client/SmbPopup";
import H from "@/utils/helper";
import SmbAddBrandingDetailsPopUp from "@/components/smb-client/SmbAddBrandingDetailsPopUp.vue";
import smbMethods from "@/mixins/smbMethods";
import LogRocket from "logrocket";

export default {
  name: "Vocabulary",
  components: {
    Loader,
    SmbTitles,
    SmbGeneralButton,
    SmbActionBtn,
    SmbAddBrandingDetailsPopUp,
  },
  mixins: [smbMethods],
  data: () => ({
    vocabularyButtons: VOCABULARY_BUTTONS,
    isLoading: false,
    subjectId: SUBJECT_IDS.VOCABULARY.GENERAL,
    nameOfLastAction: "",
    lastClickedBtnData: {},
    isShowBrandingPopUp: false,
    emitNameForActionAfterPopup: "",
  }),
  async created() {
    if (
      this.$route.params &&
      this.$route.params.emitName &&
      !H.isEmpty(this.$route.params.buttonsData)
    ) {
      let { button, type = "post" } = this.$route.params.buttonsData;
      this.setCurrentCreationContentType(type);
      await this[this.$route.params.emitName](button);
    }
  },
  computed: {
    ...mapGetters("creationContentType", ["getCurrentCreationContentType"]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    popUpTextFirstPath() {
      return `The new ${this.nameOfLastAction.toLowerCase()} ${
        this.getCurrentCreationContentType
      }  is now being generated.`;
    },
    popUpTextSecondPath() {
      return this.getCurrentSmbCustomer.primary_phone
        ? `You will be notified via SMS to ${H.formatPhoneNumber(
            this.getCurrentSmbCustomer.primary_phone,
            false
          )} once ready (about 1 min).`
        : "You can check it out in the Pending content section in ~30 seconds.";
    },
    popUpText() {
      return `${this.popUpTextFirstPath} ${this.popUpTextSecondPath}`;
    },
  },
  methods: {
    ...mapActions("smbClientSelection", ["createPosts"]),
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    ...mapMutations("creationContentType", ["setCurrentCreationContentType"]),
    async clickOnBtnHandler(buttonData) {
      this.isShowBrandingPopUp = false;
      try {
        LogRocket.track("content_generation_button");
        buttonData.isLoading = true;
        let params = {
          customerId: this.getCurrentSmbCustomer.id,
          subject: buttonData.name,
          topicSubId: [this.subjectId],
          bySmb: true,
          type: this.getCurrentCreationContentType,
        };

        await this.createPosts(params);
        this.nameOfLastAction = buttonData.name;
        this.setPopupSettings({
          isShowPopup: true,
          popUpText: this.popUpText,
        });
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        buttonData.isLoading = false;
      }
    },
    async checkIsNeedBrandingUpdatesBeforeSurpriseMe(buttonData) {
      if (this.isNeedToUpdateBranding) {
        this.isShowBrandingPopUp = true;
        this.lastClickedBtnData = buttonData;
        this.emitNameForActionAfterPopup = "clickOnBtnHandler";
      } else {
        await this.clickOnBtnHandler(buttonData);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  @media screen and (min-width: 760px) {
    ::v-deep {
      .smb-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #92c4ff;
        padding: 120px 20px 45px 20px;
        &__title {
          font-weight: 700;
          font-size: 36px;
          line-height: 140%;
        }
        &__sub-title {
          font-weight: 400;
          font-size: 30px;
          line-height: 140%;
          color: #fff;
          margin-top: 80px;
        }
      }
    }
  }
  .buttons-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 20px;
    flex: 1;
    @media screen and (min-width: 760px) {
      padding: 35px 20px;
      max-width: 600px;
    }
    ::v-deep {
      button {
        margin-bottom: 10px;
        @media screen and (min-width: 760px) {
          margin-bottom: 17px;
          font-size: 20px;
        }
      }
    }
  }
  &__footer {
    @media screen and (min-width: 760px) {
      .button-item {
        max-width: 300px;
        margin: 0 auto 30px auto;
      }
    }
  }
}
</style>
